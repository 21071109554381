<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Операций<br> на странице</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'app-points-add' }"
          >
            Пополнить баланс
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="t('Search') + '...'"
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              item-text="text"
              item-value="value"
              class="excerpt-filter-select"
              placeholder="Выберите тип"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ t(label) }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refExcerptListTable"
      :items="fetchPointHistory"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="У Вас ещё не было операций с баллами"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(excerptStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>
      <template #head(client)>
        {{ t('Сlient') }}
      </template>
      <template #head(total)>
        {{ t('Total') }}
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        #{{ data.value }}
      </template>

      <!-- Column: Excerpt Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`excerpt-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveExcerptStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveExcerptStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`excerpt-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ t(data.item.status) }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.client.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.excerptStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(dateCreate)="data">
        <span class="text-nowrap">
          {{ new Date(data.value).toLocaleDateString() + " " + new Date(data.value).toLocaleTimeString() }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(sum)="data">
        <div :class="'text-nowrap text-'+(data.value > 0 ? 'success' : 'danger')">
          <b>
            {{ data.value }}
            <feather-icon
              icon="WindIcon"
              color="red"
            />
          </b>
        </div>
      </template>

      <template #cell(newBalance)="data">
        <div class="text-nowrap">
          <b>
            {{ data.value }}
            <feather-icon
              icon="WindIcon"
              color="red"
            />
          </b>
        </div>
      </template>

      <template #cell(operation)="data">
        {{ GetOparationTitle(data.item) }}
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalExcerpts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar,
  BPagination, BTooltip, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import usePointList from './usePointList'
import pointListStoreModule from './pointListStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BButton,

    vSelect,
  },
  setup() {
    const { t } = useI18nUtils()
    const POINT_LIST_APP_STORE_MODULE_NAME = 'app-point-list'
    // Register module
    if (!store.hasModule(POINT_LIST_APP_STORE_MODULE_NAME)) store.registerModule(POINT_LIST_APP_STORE_MODULE_NAME, pointListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POINT_LIST_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_LIST_APP_STORE_MODULE_NAME)
    })

    const typeOptions = [
      { value: 'file-buy', label: 'Покупка', desc: 'Покупка файла #' },
      { value: 'file-sale', label: 'Продажа', desc: 'Продажа файла #' },
      { value: 'file-upload', label: 'Загрузка', desc: 'Загрузка файла #' },
      { value: 'file-referal-upload', label: 'Загрузка рефералом', desc: 'Загрузка файла вашим рефералом #' },
      { value: 'file-referal-sale', label: 'Продажа рефералом', desc: 'Продажа файла вашим рефералом #' },
      { value: 'payment', label: 'Покупка баллов', desc: 'Покупка баллов #' },
    ]
    const GetOparationTitle = item => {
      const option = typeOptions.filter(ii => ii.value === item.operation)[0]
      if (option) {
        return `${option.desc}${item.idObjectOperation}`
      }
      return item.operation
    }

    const {
      fetchPointHistory,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,
      statusFilter,

      refetchData,

      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = usePointList()
    return {
      GetOparationTitle,
      fetchPointHistory,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,

      statusFilter,

      refetchData,

      typeOptions,

      avatarText,
      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,
      t,
    }
  },
}
</script>
<style lang="scss">
  .per-page-selector {
  width: 90px;
}

.excerpt-filter-select {
  min-width: 242px;

::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
